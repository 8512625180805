import React from "react";

import "./About.css";

export default function About() {
  return (
    <>
      <div
        id="about"
        className="container"
        style={{ Height: "10vh", marginTop: "10vh" }}
      >
        <h2>About Me</h2>
        <div className="container" style={{ margin: "40px" }}>
          <div className="d-flex align-items-center justify-content-sm-center">
            <h3>
              Hello there! Wilson here. I am a iOS engineer based in NYC with a
              background in physical therapy and passion for health and fitness.
              See what I'm up to
              <a className="here-link" href="https://github.com/wilsonckm">
                {" "}
                here
              </a>
              !
              <br />
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
